const is_mobile = IB.currentDevice === 'mobile'
let $fastbooking
let fastbooking_state
let $place_to_go
let fbStateUpdateEvent

const DESTINATION_TYPES = {
  h: 'hotel',
  d: 'destination',
  s: 'specialty',
}

export const UPDATE_FASTBOOKING_STATE_EVENT = 'fastbooking:state:update'

function init() {
  $fastbooking = is_mobile ? $('.mobile-fastbooking-form') : $('.fastbooking')
  fastbooking_state = readState() || {}
  $place_to_go = getSelectedDestinationSelector()
  if (readState() === null) {
    setFields()
  }
}

function writeState() {
  localStorage.setItem('fastbooking_state', JSON.stringify(fastbooking_state))
}

function readState() {
  let item = localStorage.getItem('fastbooking_state')
  item = item && JSON.parse(item)
  return item
}

function readField(field = null) {
  let state = readState()
  state = field && state && state[field]
  return state
}

function setFields() {
  // Gets fields data and saves to state
  addSelectedDestinationToState()
  addDatesToState()
  addOccupancyToState()
  addRoomsToState()
  addPromocodeToState()

  // saves in localStorage
  writeState()

  triggerUpdateEvent()
}

/**
 * Gets ID and Name from place to go selector
 */
function getSelectedDestinationSelector() {
  let fbDestinationSelector = is_mobile ? $('#mobile-hotels-selector') : $('#vo_booking_place_to_go')

  if (!fbDestinationSelector.length) {
    const staticHotel = $('.js-selected-fhi-hotel-name .js-fhi-hotel-code')
    staticHotel && (fbDestinationSelector = staticHotel)
  }

  return fbDestinationSelector
}
function getSelectedDestinationId() {
  return ($place_to_go.length && $place_to_go.val()) || 0
}

function getSelectedDestinationType() {
  if (!$place_to_go.length) return
  return DESTINATION_TYPES[$place_to_go.val().charAt(0)]
}
function getSelectedDestinationName() {
  return $(`${$place_to_go.selector} option:selected`).text()
}
function addSelectedDestinationToState() {
  if (getSelectedDestinationId() === 0) {
    fastbooking_state = {}
  }

  if (!getSelectedDestinationId().length) return

  fastbooking_state = {
    place_to_go: {
      id: getDestinationIdWithoutLetter(),
      name: getSelectedDestinationName(),
      type: getSelectedDestinationType(),
      id_and_type: getSelectedDestinationId(),
    },
  }
}
function getDestinationIdWithoutLetter() {
  const selectedDestinationId = getSelectedDestinationId()
  return selectedDestinationId ? selectedDestinationId.substring(1) : ''
}
function isDestinationHotel() {
  return getSelectedDestinationType() === 'hotel'
}

/**
 * Gets check in and check out dates
 */
function getCheckInDate() {
  return $fastbooking.find('.check-in').val()
}
function getCheckOutDate() {
  return $fastbooking.find('.check-out').val()
}
// adds check in and check out dates to state
function addDatesToState() {
  const check_in_date = getCheckInDate()
  const check_out_date = getCheckOutDate()

  if (!is_mobile) $fastbooking.find('.check-out').trigger('change')

  if (check_in_date !== '' || check_out_date !== '') {
    $.extend(fastbooking_state, {
      dates: {
        check_in: check_in_date,
        check_out: check_out_date,
      },
    })
  }
}

/**
 * Gets total occupancy data per adults, children and total people
 *
 * @return object
 *         { adults: 2, children: 1, total: 3 }
 */
function getOccupancyField() {
  const people = {}
  people.adults = 0
  people.children = 0
  people.total = 0

  const total_rooms = is_mobile
    ? +$fastbooking.find('[name="vo_booking[room_count]"]').val()
    : +$fastbooking.find('.result-number-of-rooms').text()

  let adults_input
  let children_input
  for (let i = 0; i < total_rooms; i++) {
    adults_input = $fastbooking.find(`.js-input-number[name="vo_booking[rooms][${i}][adults_count]"]`)
    children_input = $fastbooking.find(`.js-input-number[name="vo_booking[rooms][${i}][children_count]"]`)

    people.adults += +adults_input.val()
    people.total += +adults_input.val()

    people.children += +children_input.val()
    people.total += +children_input.val()
  }

  return people
}

function addOccupancyToState() {
  const people_data = getOccupancyField()
  $.extend(fastbooking_state, { people: people_data })
}

/**
 * Gets room info like rooms_info helper in Rails
 *
 * @return object
 *         0: { adults_count: 2, children_count: 1, ages: [13] }
 *         1: { adults_count: 2, children_count: 0 }
 */
function getRoomsInfo() {
  const rooms_info = {}
  let $age_inputs_in_room
  let $room_row
  const total_rooms = is_mobile
    ? +$fastbooking.find('[name="vo_booking[room_count]"]').val()
    : +$fastbooking.find('.result-number-of-rooms').text()

  for (let n_room = 0; n_room < total_rooms; n_room++) {
    rooms_info[n_room] = {}

    // count total adults and children in each room
    rooms_info[n_room]['adults_count'] = +$fastbooking.find(`[name="vo_booking[rooms][${n_room}][adults_count]"]`).val()
    rooms_info[n_room]['children_count'] = +$fastbooking
      .find(`[name="vo_booking[rooms][${n_room}][children_count]"]`)
      .val()

    // get children ages
    $room_row = $($fastbooking.find('.js-room-row')[n_room]) // get the div room row, parent of the room inputs
    $age_inputs_in_room = $room_row.find('.js-age') // check if the age selector is present in the div room row
    if ($age_inputs_in_room.length) {
      // it is present, so...
      rooms_info[n_room]['ages'] = getChildrenInRoomAges($room_row, $age_inputs_in_room) // ...get the ages
    }
  }

  return rooms_info
}

/**
 * Gets the ages of the children in a room
 */
function getChildrenInRoomAges($room_row, $age_inputs_in_room) {
  const children = []

  for (let child_in_room = 0; child_in_room < $age_inputs_in_room.length; child_in_room++) {
    children.push(+$age_inputs_in_room[child_in_room].value)
  }

  return children
}

function addRoomsToState() {
  const rooms_object = getRoomsInfo()
  $.extend(fastbooking_state, { rooms_info: rooms_object })
}

function addPromocodeToState() {
  const promocode = $fastbooking.find('[name="vo_booking[promo_code]"]')[0]
  if (promocode?.value) {
    $.extend(fastbooking_state, { promocode: { code: promocode.value, isValid: '', crsCode: 0 } })
  }
}

function overridePromocodeInState(code, isValid, crsCode) {
  // Gets fields data and saves to state
  addSelectedDestinationToState()
  addDatesToState()
  addOccupancyToState()
  addRoomsToState()

  $.extend(fastbooking_state, { promocode: { code, isValid, crsCode } })

  // saves in localStorage
  writeState()
}

function isSharedSearch() {
  return /shared/.test(document.referrer)
}

function triggerUpdateEvent() {
  fbStateUpdateEvent = fbStateUpdateEvent || new Event(UPDATE_FASTBOOKING_STATE_EVENT)
  document.dispatchEvent(fbStateUpdateEvent)
}

const FastbookingState = {
  init,
  getState: readState,
  setState: setFields,
  getField: readField,
  getDestinationId: getDestinationIdWithoutLetter,
  getDestinationType: getSelectedDestinationType,
  getSelectedDestinationName,
  isDestinationHotel,
  overridePromocodeInState,
  isSharedSearch,
  triggerUpdateEvent,
}

if (typeof IB === 'undefined') {
  window.IB = {}
}

window.IB.fastbooking_state = FastbookingState

export default FastbookingState
